import React from "react";

import "./animation.css";

const PortalAnimation = ({ fade }) => {
  let bobbles = [];
  for (let i = 0; i < 100; i++) {
    bobbles.push(<div className="bubble" key={i} />);
  }

  return (
    <div className={`animation-container ${fade ? "fade" : ""}`}>
      <div className="bubble-wrap">{bobbles}</div>
    </div>
  );
};

export default PortalAnimation;

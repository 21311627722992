import React, { useEffect, useState } from "react";
import "./warning.css";

const Warning = ({ warning, setWarning }) => {
  const [state, setState] = useState("hidden");

  useEffect(() => {
    if (warning) {
      // animation in
      setTimeout(() => setState("visible"), 100);

      // animation out
      setTimeout(() => setState("animation-out"), 9000);

      // reset warning
      setTimeout(() => {
        setWarning(false);
        setState("hidden");
      }, 10000);
    }
  }, [warning, setWarning]);

  const val = warning ? (
    <div className={state} id="pop-up-warning">
      <svg version="1.1" id="Capa_1" width="25px" height="25px" fill="#48bcd0" viewBox="0 0 106.031 106.031">
        <g>
          <path
            d="M53.015,76.703c-5.815,0-10.528-4.712-10.528-10.528V10.528C42.487,4.714,47.201,0,53.015,0
		c5.813,0,10.528,4.714,10.528,10.528v55.647C63.544,71.991,58.83,76.703,53.015,76.703z"
          />
          <circle cx="52.34" cy="97.007" r="9.024" />
        </g>
      </svg>

      {warning.msg}
    </div>
  ) : null;

  return val;
};

export default Warning;

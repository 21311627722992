import React from 'react';
import './faq.css';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';


const Faq = () => (
    <div id="faq">
        <h1>FAQs</h1>
      <Accordion atomic={true}>
  
        <AccordionItem title="How long do I need to stake for?">
          <DummyContentOne />
        </AccordionItem>
  
        <AccordionItem title="Does everyone get an NFT for staking?">
          <DummyContentTwo />
        </AccordionItem>
  
        <AccordionItem title="How much is the staking APY?">
          <DummyContentThree />
        </AccordionItem>

        <AccordionItem title="Where can I stake?">
          <DummyContentFour />
        </AccordionItem>

        {/* <AccordionItem title="Question 5">
          <DummyContentTwo />
        </AccordionItem> */}
  
      </Accordion>
    </div>
  );
  
  const DummyContentOne = () => (
    <p>
      The minimum staking period is 3-months, the maximum APY will be achieved for staking for the full 12 months.
    </p>
  );

  const DummyContentTwo = () => (
    <p>
      Everyone will get a standard NFT, but 500 lucky stakers will get a limited edition NFT that comes with extra rewards such as bonus ADS and VIP access.
    </p>
  );

  const DummyContentThree = () => (
    <p>
      Staking APY is up to 10% - as people unstake and you keep staking, your APY will increase based on the unclaimed rewards left behind by users who unstake early.
    </p>
  );

  const DummyContentFour = () => (
    <p>
      On <a href="https://lattice.exchange/" target="blank">Lattice Exchange</a> from the 24th of November.
    </p>
  );

  // const DummyContentFive = () => (
  //   <p style={{ padding: '18px' }}>
  //     On Lattice Exchange from the 24th of November.
  //   </p>
  // );


  export default Faq;
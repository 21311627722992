import React from "react"
import "./welcome.css"

function welcome({ type }) {
  switch (type) {
    case "alex":
      return (
        <div className="alkimi-card video">
          <div className="left">
            <h1>WELCOME TO AlEx THE ALKIMIST</h1>
            <p className="first">
              AlEx knew he wasn’t a regular scientist, his classmates were always impressed by his ability to advertise his solution at the
              science fair. AlEx’s science career was short lived when he got sick of inefficient experiments; lack of transparent data and
              the exorbitant costs of running a lab.
            </p>
            <p className="second">
              AlEx could see from the data he was born to be an entrepreneur; so after carefully validating transparent sets of auditable
              data he created Alkimi and founded the Alkimi Exchange, and a community of Alkimists, just like himself, began to form.
            </p>
          </div>
          <div className="right">
            {/* <img className='welcome-image' src={welcome}></img> */}
            <video width="100%" controls>
              <source src="https://res.cloudinary.com/mselbekk-com/video/upload/v1637754355/Untitled_x1hheo.mp4" type="video/mp4"></source>
              <source src="https://res.cloudinary.com/mselbekk-com/video/upload/v1637754355/Untitled_x1hheo.mp4" type="video/ogg"></source>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )
    case "mutate-alex":
      return (
        <div className="alkimi-card video">
          <div className="left">
            <h1>How To Experiment With Your Community AlEx </h1>
            <p className="first">
              Alkimi Exchange announced, that holders of AlEx The Alkimist Polygon NFTs will be able to experiment using their Elemental
              NFTs. This comes following mysterious airdrops to individuals who have staked on Lattice Exchange's Alkimi Staking programme.
            </p>
            <p className="second">In this video, we walk you through how to experiment with your AlEx The Alkimist Community NFT!</p>
          </div>
          <div className="right">
            {/* <img className='welcome-image' src={welcome}></img> */}
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube-nocookie.com/embed/eZCfdIctwOs"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowfullscreen
            />
          </div>
        </div>
      )
    default:
      return null
  }
}

export default welcome

import React, { useRef } from "react"
import MutateBox from "../components/MutateBox/MutateBox"

const Mutate = ({ signer }) => {
  // if signer is null redirect to home page
  if (signer === null) {
    window.location.href = "/"
    return <div>Redirecting...</div>
  }

  return <MutateBox signer={signer} />
}
export default Mutate

import React from "react"
import "./hero.css"
import hero from "../../assets/img/banner_desktop.png"
import heroMobile from "../../assets/img/banner_mobile.png"

const Hero = () => (
  <div id="hero">
    <img className="hero-banner desktop" src={hero} alt="hero"></img>
    <img className="hero-banner mobile" src={heroMobile} alt="hero mobile"></img>
  </div>
)

export default Hero

import React, { useState } from "react"
import "./App.css"
import Profile from "./components/profile/profile"
import Layout from "./pages/Layout"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import Mutate from "./pages/Mutate"

const App = () => {
  const [state, setState] = useState({
    isConnected: false,
    signer: null,
    nftBalance: 0,
    walletType: "",
  })

  return (
    <div className="App">
      {/* Global Layout */}

      <BrowserRouter>
        <Layout state={state} setState={setState}>
          <Routes>
            {/* Make routes  */}

            <Route path="/" element={<Home />} />
            <Route
              path="/profile"
              element={<Profile className="App-Profile" walletType={state.walletType} signer={state.signer}></Profile>}
            />
            <Route path="/mutate" element={<Mutate signer={state.signer} />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  )
}

// class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isConnected: true,
//       signer: 1,
//       nftBalance: 0,
//       homeActive: false,
//       profileActive: true,
//       walletType: ""
//     };
//     this.connectionHandler.bind(this);
//     this.togglePages.bind(this);
//   }

//   connectionHandler = (isConnected, signer, nftBalance, walletType) => {
//     this.setState({ isConnected, signer, nftBalance, walletType });
//   }

//   togglePages = (home) => {
//     if (home) {
//       this.setState({
//         homeActive: true,
//         profileActive: false
//       });
//     } else {
//       this.setState({
//         homeActive: false,
//         profileActive: true
//       });
//     }
//   }

//   render() {

//     let activePage = <LandingPage></LandingPage>;

//     if ((!this.state.isConnected || this.state.nftBalance < 1) || this.state.homeActive)
//       activePage = <LandingPage></LandingPage>;
//     else if (this.state.profileActive)
//       activePage = <Profile className='App-Profile' walletType={this.state.walletType} signer={this.state.signer}></Profile>;

//     return (
//       <div className='App'>
//         <Navigation onPageChange={this.togglePages} onConnect={this.connectionHandler}></Navigation>
//         {activePage}
//       </div>
//     );
//   }
// }

export default App

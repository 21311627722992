import React from "react"
import Hero from "../components/Hero/hero"
import Welcome from "../components/Welcome/welcome"
import Game from "../components/Game/game"
import Gallery from "../components/Gallery/gallery"
import CardOne from "../components/CardOne/card-one"
import CardTwo from "../components/CardTwo/card-two"
import CardThree from "../components/CardThree/card-three"
import Community from "../components/Community/community"
import Faq from "../components/Faqs/faq"
import Footer from "../components/Footer/footer"
import CardWrapper from "../layout/CardWrapper"
// import Female from '../components/Female/female';
import MintElementals from "../components/MintElementals/MintElementals"

const Home = () => {
  return (
    <>
      <Hero />
      {/* <CardWrapper>
        <MintElementals title="Mint elementals" />
      </CardWrapper> */}
      {/* <Female></Female> */}
      {/* <Game></Game> */}
      <Welcome type="mutate-alex" />
      <Gallery type="mutate-alex" />
      <Welcome type="alex" />
      <Gallery type="alex" />
      <CardOne />
      <CardTwo />
      <CardThree />
      <Community />
      <Faq />
      <Footer />
    </>
  )
}

export default Home

import React from "react"
import "./footer.css"
import alkimiLogo from "../../assets/img/logo.png"
import discord from "../../assets/img/discord.png"
import telegram from "../../assets/img/Telegram-logo.png"
import twitter from "../../assets/img/twitter.png"
import opensea from "../../assets/img/opensea-logo-4.png"

const Footer = () => {
  return (
    <nav>
      <div id="footer">
        <div id="logo">
          <div className="logo">
            <a href="#!" className="brand-logo">
              <img className="" src={alkimiLogo} alt="footer-logo"></img>
            </a>
          </div>
        </div>
        <div id="links">
          <a href="https://discord.gg/Mjqc76qd" target="_blank" rel="noreferrer">
            <button className="border-gradient social">
              <img className="" src={discord} alt="discord"></img>
            </button>
          </a>

          <a href="https://twitter.com/alkimiexchange?lang=en" target="_blank" rel="noreferrer">
            <button className="border-gradient social">
              <img className="" src={twitter} alt="twitter"></img>
            </button>
          </a>

          <a href="https://opensea.io/collection/alexthealkimist" target="_blank" rel="noreferrer">
            <button className="border-gradient social">
              <img className="opensea" src={opensea} alt="opensea"></img>
            </button>
          </a>

          <a href="https://t.me/Alkimi_Exchange" target="_blank" rel="noreferrer">
            <button className="border-gradient social">
              <img className="" src={telegram} alt="telegram"></img>
            </button>
          </a>
          {/* <a href=""><button className="claim-nft">Claim NFT</button></a> */}
        </div>
      </div>
    </nav>
  )
}

export default Footer

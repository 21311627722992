import React, { useState } from "react";
import "./MintElements.css";

import ButtonDefault from "../Button/Button";

import carbon from "../../assets/img/elementals/carbon.gif";
import magnesium from "../../assets/img/elementals/magnesium.gif";
import nitrogen from "../../assets/img/elementals/nitrogen.gif";
import silicon from "../../assets/img/elementals/silicon.gif";

const MintElementals = ({ title }) => {
  const [selected, setSelected] = useState([]);

  const elements = [
    { id: 1, img: carbon, alt: "carbon" },
    { id: 2, img: magnesium, alt: "magnesium" },
    { id: 3, img: nitrogen, alt: "nitrogen" },
    { id: 4, img: silicon, alt: "silicon" },
  ];

  const handleClick = (id) => {
    // if the clicked element is already selected, remove it from the selected array
    if (selected.includes(id)) setSelected(selected.filter((element) => element !== id));
    // if the clicked element is not already selected, add it to the selected array
    else setSelected([...selected, id]);
  };

  // const handleMint = () => {
  //   console.log("MINT");
  // };

  return (
    <div className="elementals-wrapper">
      {title && <h2 className="txt-center">{title}</h2>}

      <div className="elementals-container">
        {elements.map((element) => (
          <img
            className={`element ${selected.includes(element.id) ? "selected" : ""}`}
            key={element.id}
            src={element.img}
            alt={element.alt}
            onClick={() => handleClick(element.id)}
          />
        ))}
      </div>
      <p className="txt-center selected-el">
        selected: <span className="selected-el-nr">{selected.length}</span>
      </p>

      {/* <ButtonDefault type="large" label="EXPERIMENT" action={handleMint} /> */}
    </div>
  );
};

export default MintElementals;

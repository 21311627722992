import React, { useState } from "react"
import Navigation from "../components/Navigation/navigation"
import Warning from "../components/Warning/Warning"

const Layout = ({ children, setState, state }) => {
  const [warning, setWarning] = useState(false)

  return (
    <>
      <Navigation state={state} setState={setState} setWarning={setWarning} />
      {children}
      <Warning warning={warning} setWarning={setWarning} />
    </>
  )
}
export default Layout

import React from "react"
import "./button.css"
import { useNavigate } from "react-router-dom"
import opensea from "../../assets/img/logos/opensea.png"
import looksrare from "../../assets/img/logos/looksrare.png"

const ButtonDefault = ({ label, redirect, action, type, src, disabled }) => {
  let navigate = useNavigate()

  const handleClick = () => {
    if (redirect) navigate(redirect)
    else if (action) action()
    else return
  }

  switch (type) {
    case "gradient":
      return (
        <button onClick={handleClick} className="btn-gradient">
          {label}
        </button>
      )
    case "large":
      return (
        <button onClick={handleClick} className="btn-large">
          {label}
        </button>
      )
    case "getAlex-opensea":
      return (
        <a href={src} target="_blank" className="btn-getAlex t-opensea">
          <img src={opensea} />
        </a>
      )
    case "getAlex-looksrare":
      return (
        <a href={src} target="_blank" className="btn-getAlex t-looksrare">
          <img src={looksrare} />
        </a>
      )
    default:
      return (
        <button disabled={disabled ? disabled : false} onClick={handleClick} className="btn-default">
          {label}
        </button>
      )
  }
}
export default ButtonDefault

import React, { useEffect, useState } from "react"
import "./MutateBox.css"
import ButtonDefault from "../Button/Button"
import Overlay from "../Overlay/Overlay"
import Warning from "../Warning/Warning"

import MintElementals from "../MintElementals/MintElementals"
import { ethers } from "ethers"
import * as elementalData from "../../assets/data/abi/AlkimiElementals.json"
import * as polygonAlexData from "../../assets/data/abi/PolygonCommunityAlex.json"
import * as mutantAlexData from "../../assets/data/abi/AlkimiMutantAlex.json"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper.min.css"

const MutateBox = ({ signer }) => {
  const [nfts, setNfts] = useState(null) // null | 'empty' | { id: string, img: string }
  const [activeNft, setActiveNft] = useState(0)
  const [activeElement, setActiveElement] = useState(0)
  const [elements, setElements] = useState(null) // null | 'empty' | { id: string, img: string }
  const [overlay, setOverlay] = useState(false)
  const [warning, setWarning] = useState(false)
  const [mutationURI, setMutationURI] = useState("")

  const [alexApproval, setAlexApproval] = useState(false)
  const [elementalApproval, setElementalApproval] = useState(false)

  const approveAlex = async () => {
    setAlexBTN({ label: "Approving...", disabled: true, action: null })

    console.log("activeNft", activeNft)
    console.log("activeElement", activeElement)

    let polyAlex
    let mutantAlexAddress

    let chainId = await signer.getChainId()

    if (chainId === 1337) {
      // Localnet
      polyAlex = new ethers.Contract(polygonAlexData.localNetAddress, polygonAlexData.abi, signer)
      mutantAlexAddress = mutantAlexData.localNetAddress
    } else if (chainId === 137) {
      // Polygon
      polyAlex = new ethers.Contract(polygonAlexData.polyAddress, polygonAlexData.abi, signer)
      mutantAlexAddress = mutantAlexData.polyAddress
    }

    try {
      console.log("activeNft", activeNft)
      let tx = await polyAlex.approve(mutantAlexAddress, activeNft.id)
      const receipt = await tx.wait()
      setAlexApproval(true)
      setAlexBTN({ label: "Approved", disabled: true, action: null })
    } catch (e) {
      console.error(e)
      setWarning({ type: "error", msg: "Approve Alex Transaction Cancelled!" })
      resetAlexBtn()
    }
  }

  const approveElemental = async () => {
    setElementalBTN({ label: "Approving...", disabled: true, action: null })
    let elementals
    let mutantAlexAddress
    let chainId = await signer.getChainId()
    if (chainId === 1337) {
      // Localnet
      elementals = new ethers.Contract(elementalData.localNetAddress, elementalData.abi, signer)
      mutantAlexAddress = mutantAlexData.localNetAddress
    } else if (chainId === 137) {
      // Polygon
      elementals = new ethers.Contract(elementalData.polyAddress, elementalData.abi, signer)
      mutantAlexAddress = mutantAlexData.polyAddress
    }
    try {
      let tx = await elementals.approve(mutantAlexAddress, activeElement.id)
      const receipt = await tx.wait()
      setElementalApproval(true)
      setElementalBTN({ label: "Approved", disabled: true, action: null })
    } catch (e) {
      console.error(e)
      setWarning({ type: "error", msg: "Approve Elemental Transaction Cancelled!" })
      resetElementalBtn()
    }
  }

  const handleMutate = async () => {
    setExperimentBTN({ label: "Experimenting...", disabled: true, action: null })
    // validate selections
    console.log("Mutate!")
    let mutantAlex
    let mutantAlexAddress
    let chainId = await signer.getChainId()
    if (chainId === 1337) {
      // Localnet
      mutantAlex = new ethers.Contract(mutantAlexData.localNetAddress, mutantAlexData.abi, signer)
      mutantAlexAddress = mutantAlexData.localNetAddress
    } else if (chainId === 137) {
      // Polygon
      mutantAlex = new ethers.Contract(mutantAlexData.polyAddress, mutantAlexData.abi, signer)
      mutantAlexAddress = mutantAlexData.polyAddress
    }

    try {
      let mutantAlex = new ethers.Contract(mutantAlexAddress, mutantAlexData.abi, signer)
      let tx = await mutantAlex.mutateAlex(activeNft.id, activeElement.id)
      let receipt = await tx.wait(2)
      let address = await signer.getAddress()
      let numOwnedMutants = await mutantAlex.balanceOf(address)
      let lastCreatedId = await mutantAlex.tokenOfOwnerByIndex(address, numOwnedMutants.sub(1))
      let uri = await mutantAlex.tokenURI(lastCreatedId)
      setMutationURI(uri)

      // open overlay
      setOverlay(true)
    } catch (e) {
      console.error(e)
      setWarning({ type: "error", msg: "Mutation Transaction Cancelled!" })
      resetExperimentBtn()
    }
  }

  const getNFTs = async () => {
    let polyAlex
    let elementals
    let chainId = await signer.getChainId()
    if (chainId === 1337) {
      // Localnet
      polyAlex = new ethers.Contract(polygonAlexData.localNetAddress, polygonAlexData.abi, signer)
      elementals = new ethers.Contract(elementalData.localNetAddress, elementalData.abi, signer)
    } else if (chainId === 137) {
      // Polygon
      polyAlex = new ethers.Contract(polygonAlexData.polyAddress, polygonAlexData.abi, signer)
      elementals = new ethers.Contract(elementalData.polyAddress, elementalData.abi, signer)
    }

    let address = await signer.getAddress()

    if (!polyAlex) {
      // no polygon alex
      setNfts("empty")
    } else {
      try {
        console.log("Get NFTs!")

        let numOwnedAlex = await polyAlex.balanceOf(address)

        let ownedPolyAlex = []

        for (let i = 0; i < numOwnedAlex; i++) {
          let polyId = await polyAlex.tokenOfOwnerByIndex(address, i)

          console.log("polyId", polyId)

          let communityUri = await polyAlex.tokenURI(polyId)

          const res = await fetch(communityUri)
          const data = await res.json()

          console.log("data", data)
          ownedPolyAlex.push({ id: polyId, img: data.image })
        }

        if (ownedPolyAlex.length > 0) {
          setNfts(ownedPolyAlex)
          setActiveNft(ownedPolyAlex[0])
        } else {
          setNfts("empty")
        }
      } catch (e) {
        // console.log("err loading nft", e);
        setElements(null)
        setNfts(null)
      }
    }

    if (!elementals) {
      // no elementals
      setElements("empty")
    } else {
      try {
        console.log("Get elements!")
        let numOwnedElementals = await elementals.balanceOf(address)

        let ownedElementals = []
        for (let i = 0; i < numOwnedElementals; i++) {
          let id = await elementals.tokenOfOwnerByIndex(address, i)
          const res = await fetch(`https://www.alkimi-nft-server.xyz/api/elemental/${id}`)
          const data = await res.json()
          ownedElementals.push({ id: id, img: data.image })
        }
        if (ownedElementals.length > 0) {
          setElements(ownedElementals)
          setActiveElement(ownedElementals[0])
        } else {
          setElements("empty")
        }
      } catch (e) {
        setElements(null)
        setNfts(null)
      }
    }
  }

  useEffect(() => {
    getNFTs()
  }, [])

  const handleScrool = (_, index) => setActiveNft(nfts[index])

  const [alexBTN, setAlexBTN] = useState({ label: "Approve Alex", disabled: false, action: approveAlex })
  const [elementalBTN, setElementalBTN] = useState({ label: "Approve Elemental", disabled: false, action: approveElemental })
  const [experimentBTN, setExperimentBTN] = useState({ label: "Run Experiment", disabled: false, action: handleMutate })

  const resetAlexBtn = () => setAlexBTN({ label: "Approve Alex", disabled: false, action: approveAlex })
  const resetElementalBtn = () => setElementalBTN({ label: "Approve Elemental", disabled: false, action: approveElemental })
  const resetExperimentBtn = () => setExperimentBTN({ label: "Run Experiment", disabled: false, action: handleMutate })

  return (
    <div className="box-container">
      <div className="box">
        <div className="inner">
          <h2 className="title">
            <span className="nr-circle">1</span>

            {nfts === "empty" ? "Get Community Alex" : "Choose a Community Alex"}
          </h2>
          <NFTsSection nfts={nfts} handleScrool={handleScrool} />
        </div>

        <div className="inner">
          <h2 className="title">
            <span className="nr-circle">2</span>
            {elements === "empty" ? "Mint an Elemental" : "Choose an Elemental"}
          </h2>
          <div className="elements-container">
            <ElementalsSection
              elements={elements}
              setActiveElement={setActiveElement}
              handleScrool={handleScrool}
              activeElement={activeElement}
            />
          </div>
        </div>
        <div>
          {elements && elements !== "empty" && nfts && nfts !== "empty" && (
            <>
              <div className="experiment-btns-container">
                <ButtonDefault disabled={alexBTN.disabled} action={approveAlex} label={alexBTN.label} />
                {alexApproval && <ButtonDefault disabled={elementalBTN.disabled} action={approveElemental} label={elementalBTN.label} />}
                {alexApproval && elementalApproval && (
                  <ButtonDefault disabled={experimentBTN.disabled} action={handleMutate} label={experimentBTN.label} />
                )}
              </div>
              <div style={{ textAlign: "center" }}>
                <p style={{ marginTop: "20px" }}>Please do no refresh the page during experiment as it may interupt the proccess</p>
                <p>
                  This sequence includes burning two NFTs to create a new one. Any disruption in this process may lead to you burning your
                  NFTs without receiving the end result
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      {overlay && (
        <Overlay
          tokenURI={mutationURI}
          activeElement={activeElement}
          activeNft={activeNft}
          close={async () => {
            await getNFTs()
            setOverlay(false)
            setAlexApproval(false)
            setElementalApproval(false)
            resetExperimentBtn()
            resetElementalBtn()
            resetAlexBtn()
          }}
        />
      )}
      <Warning warning={warning} setWarning={setWarning} />
    </div>
  )
}

const Loading = () => <div className="loading">...Loading</div>

const ElementalsSection = ({ elements, setActiveElement, activeElement }) => {
  switch (elements) {
    case "empty":
      return <MintElementals />
    case null:
      return <Loading />
    default:
      return (
        // <Carousel
        //   className="carouselone"
        //   itemsToShow={4}
        //   breakPoints={[
        //     { width: 0, itemsToShow: 1, showArrows: elements.length > 1 ? true : false, pagination: elements.length > 1 ? true : false },
        //     { width: 500, itemsToShow: 3, showArrows: elements.length > 3 ? true : false, pagination: elements.length > 3 ? true : false },
        //     { width: 768, itemsToShow: 4, showArrows: elements.length > 4 ? true : false, pagination: elements.length > 4 ? true : false },
        //   ]}
        // >
        //   {elements.map((item, index) => (
        //     <div key={index}>
        //       <img
        //         className={activeElement === elements[index] ? "selected" : ""}
        //         src={item.img}
        //         width={175}
        //         height={175}
        //         key={index.id}
        //         alt="one"
        //         onClick={() => setActiveElement(elements[index])}
        //         index={index}
        //       />
        //     </div>
        //   ))}
        // </Carousel>
        <Swiper slidesPerView={"auto"} spaceBetween={30} centeredSlides={false} modules={[]} className="mySwiper">
          {elements.map((item, index) => (
            <SwiperSlide key={index}>
              <div key={index}>
                <img
                  className={activeElement === elements[index] ? "selected" : ""}
                  src={item.img}
                  width={175}
                  height={175}
                  key={index.id}
                  alt="one"
                  onClick={() => setActiveElement(elements[index])}
                  index={index}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )
  }
}

const NFTsSection = ({ nfts, handleScrool }) => {
  switch (nfts) {
    case "empty":
      return (
        <div className="get-alex-container">
          <ButtonDefault src="https://opensea.io/collection/alex-tk9hmqfbrw" type="getAlex-opensea" />
          {/* <ButtonDefault src="https://looksrare.org/collections/0xb2d4F230298Cf68164F6C5DD994068Cbb4C3D335" type="getAlex-looksrare" /> */}
        </div>
      )
    case null:
      return <Loading />
    default:
      return (
        // <Carousel
        //   showArrows={nfts.length > 1 ? true : false}
        //   pagination={nfts.length > 1 ? true : false}
        //   className="carouselone nft-carousel"
        //   itemsToShow={1}
        //   onChange={handleScrool}
        // >
        //   {nfts.map((item, index) => (
        //     <div key={index.id}>
        //       <img src={item.img} alt="one" />
        //     </div>
        //   ))}
        // </Carousel>
        <Swiper slidesPerView={"auto"} spaceBetween={30} centeredSlides={false} modules={[]} className="mySwiper">
          {nfts.map((item, index) => (
            <SwiperSlide key={index}>
              <div key={index.id}>
                <img src={item.img} alt="one" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )
  }
}

export default MutateBox

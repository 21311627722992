import React from "react"
import "./navigation.css"
import alkimiLogo from "../../assets/img/logo.png"
import alkimiLogoMobile from "../../assets/img/logo-mobile.png"
import discord from "../../assets/img/discord.png"
import telegram from "../../assets/img/Telegram-logo.png"
import twitter from "../../assets/img/twitter.png"
import opensea from "../../assets/img/opensea-logo-4.png"
import Connect from "../../components/connect/connect"
import Claim from "../../components/claim/claim"
import Wallet from "../../components/Wallet/wallet"
import ButtonDefault from "../../components/Button/Button"
import { useNavigate } from "react-router-dom"

const Navigation = ({ setState, state, setWarning }) => {
  const navigate = useNavigate()

  let activeButton
  if (!state.isConnected) activeButton = <Wallet setWarning={setWarning} onWalletConnected={setState} />
  else activeButton = <Claim signer={state.signer} />

  const links = [
    { img: discord, alt: "discord", link: "https://discord.gg/Mjqc76qd" },
    { img: twitter, alt: "twitter", link: "https://twitter.com/alkimiexchange?lang=en" },
    { img: opensea, alt: "opensea", link: "https://opensea.io/collection/alexthealkimist" },
    { img: telegram, alt: "telegram", link: "https://t.me/Alkimi_Exchange" },
  ]

  return (
    <nav>
      <div id="navbar">
        <div id="logo">
          <div className="logo desktop" onClick={() => navigate("/")}>
            <a href="/" className="brand-logo">
              <img className="" src={alkimiLogo} alt="logo" />
            </a>
          </div>
          <div className="logo mobile" onClick={() => navigate("/")}>
            <a href="/" className="brand-logo">
              <img className="" src={alkimiLogoMobile} alt="logo" />
            </a>
          </div>
        </div>

        <div id="links">
          {links.map((link) => (
            <a href={link.link} target="_blank" key={link.alt} rel="noopener noreferrer">
              <button className="border-gradient social">
                <img src={link.img} alt={link.alt}></img>
              </button>
            </a>
          ))}
          {activeButton}
          {state.isConnected && state.nftBalance > 0 && (
            <>
              <ButtonDefault redirect="/profile" label="Profile" type="gradient" />
              <ButtonDefault redirect="/mutate" label="Experiment" />
            </>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Navigation

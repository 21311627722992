import React from "react";
import "./profile-properties.css";
import PropertyBox from "../profile-properties/property-box";

const ProfileProperties = ({ attributeKeys, attributeValues }) => {
  let attributeCount = attributeKeys.length;
  let arr = Array.from(Array(attributeCount).keys());

  return (
    <div className="tile props">
      <h1>Properties</h1>
      <div className="properties">
        {arr.map((index) => {
          return (
            <>
              <PropertyBox propertyName={attributeKeys[index]} propertyValue={attributeValues[index]}></PropertyBox>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileProperties;

import React from "react";
import "./launch-game.css";

const LaunchGame = ({ signer, layers }) => {
  const launchGame = async () => {
    let gamePath = "./arcade/ITTV.html";
    let address = await signer.getAddress();
    let passName = address + "exitHash" + layers;
    console.log(passName);
    window.open(gamePath, passName);
  };

  return (
    <div className="launcher-container">
      <button className="launcherButton" onClick={launchGame}>
        Launch Game
      </button>
    </div>
  );
};

export default LaunchGame;

import React from "react";
import "./profile.css";
import pictureOne from "../../assets/img/A.png";
import leftArrow from "../../assets/img/left-arrow-2.png";
import rightArrow from "../../assets/img/right-arrow-2.png";

import ProfileDescription from "./profile-description/profile-description";
import ProfileDetails from "./profile-details/profile-details";
import ProfileProperties from "./profile-properties/profile-properties";
import LaunchGame from "../launch-game/launch-game";
// import * as nftData from "../../assets/data/abi/AlkimiNFTs-Etherium.json";
import * as nftData from "../../assets/data/abi/AlkimiNFTs.json";
import * as mutantAlexData from "../../assets/data/abi/AlkimiMutantAlex.json";
import * as polygonAlexData from "../../assets/data/abi/PolygonCommunityAlex.json";
import { ethers } from "ethers";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "Data is loading....",
      token_id: null,
      token_bonus: null,
      background: null,
      name: null,
      metadata: null,
      attributeValues: null,
      attributeKeys: null,
      attributes: null,
      imageUrl: pictureOne,
      layers: "0-0-0-0-0-0-0",
      tokensOwned: [],
      selectedIndex: 0,
      chainId: 0,
      contractAddress: "",
    };
  }

  componentDidMount() {
    this.loadNFTs();
  }

  loadNFTs = async () => {
    const signer = this.props.signer;
    let walletType = this.props.walletType;

    let address;
    let nfts;
    let polyAlex;
    let mutantAlex;
    let chainId;
    if (walletType === "stargazer") {
      console.log("version 2");
      const provider = ethers.getDefaultProvider("homestead");
      const starProvider = await window.stargazer.getProvider("ethereum");
      const web3provider = new ethers.providers.Web3Provider(starProvider);
      const signer = web3provider.getSigner();
      nfts = new ethers.Contract(nftData.ethAddress, nftData.abi, provider);
      address = await signer.getAddress();
      console.log(nfts);
    } else if (walletType === "metamask") {
      address = await signer.getAddress();
      chainId = await signer.getChainId();
      console.log(chainId);
      if (chainId === 1337) {
        // Localnet
        nfts = new ethers.Contract(nftData.localNetAddress, nftData.abi, signer);
        polyAlex = new ethers.Contract(polygonAlexData.localNetAddress, polygonAlexData.abi, signer);
        mutantAlex = new ethers.Contract(mutantAlexData.localNetAddress, mutantAlexData.abi, signer);
      } else if (chainId === 137) {
        // Polygon
        polyAlex = new ethers.Contract(polygonAlexData.polyAddress, polygonAlexData.abi, signer);
        mutantAlex = new ethers.Contract(mutantAlexData.polyAddress, mutantAlexData.abi, signer);
      } else if (chainId === 1) {
        // Ethereum Mainnet
        nfts = new ethers.Contract(nftData.ethAddress, nftData.abi, signer);
      }
      this.setState({ chainId });
    }

    // stops err from being thrown if no nfts are owned
    // if (!nfts) return;

    const tokensOwned = [];

    if (chainId === 1337) {
      let ownedNFTs = await nfts.balanceOf(address);
      if (ownedNFTs > 0) {
        for (let i = 0; i < ownedNFTs; i++) {
          let id = (await nfts.tokenOfOwnerByIndex(address, i)).toString();
          const res = await fetch(`https://alkimi-nfts.ew.r.appspot.com/api/alex/${id}`);
          const data = await res.json();
          data.contractAddress = nftData.localNetAddress;
          data.blockchain = "Hardhat";
          data.attributes["Token_Bonus"] = 0;
          tokensOwned.push(data);
        }
      }

      let numOwnedPolyAlex = await polyAlex.balanceOf(address);
      if (numOwnedPolyAlex > 0) {
        for (let i = 0; i < numOwnedPolyAlex; i++) {
          let polyId = await polyAlex.tokenOfOwnerByIndex(address, i);
          let communityUri = await polyAlex.tokenURI(polyId);
          const res = await fetch(communityUri);
          const data = await res.json();
          data.contractAddress = polygonAlexData.localNetAddress;
          data.blockchain = "Hardhat";
          data.attributes["Token_Bonus"] = 0;
          tokensOwned.push(data);
        }
      }

      let numOwnedMutantAlex = await mutantAlex.balanceOf(address);
      if (numOwnedMutantAlex > 0) {
        for (let i = 0; i < numOwnedMutantAlex; i++) {
          let id = await mutantAlex.tokenOfOwnerByIndex(address, i);
          let uri = await mutantAlex.tokenURI(id);
          const res = await fetch(uri);
          const data = await res.json();
          data.contractAddress = mutantAlexData.localNetAddress;
          data.blockchain = "Hardhat";
          data.attributes["Token_Bonus"] = 0;
          tokensOwned.push(data);
        }
      }
    } else if (chainId === 1) {
      let ownedNFTs = await nfts.balanceOf(address);
      if (ownedNFTs > 0) {
        for (let i = 0; i < ownedNFTs; i++) {
          let id = (await nfts.tokenOfOwnerByIndex(address, i)).toString();
          const res = await fetch(`https://alkimi-nfts.ew.r.appspot.com/api/alex/${id}`);
          const data = await res.json();
          data.contractAddress = nftData.ethAddress;
          data.blockchain = "Ethereum";
          tokensOwned.push(data);
        }
      }
    } else if (chainId === 137) {
      console.log("hello nurse");
      let numOwnedPolyAlex = await polyAlex.balanceOf(address);
      if (numOwnedPolyAlex > 0) {
        for (let i = 0; i < numOwnedPolyAlex; i++) {
          let polyId = await polyAlex.tokenOfOwnerByIndex(address, i);
          let communityUri = await polyAlex.tokenURI(polyId);
          const res = await fetch(communityUri);
          const data = await res.json();
          data.contractAddress = polygonAlexData.polyAddress;
          data.attributes["Token_Bonus"] = 0;
          data.blockchain = "Polygon";
          tokensOwned.push(data);
        }
      }

      let numOwnedMutantAlex = await mutantAlex.balanceOf(address);
      if (numOwnedMutantAlex > 0) {
        for (let i = 0; i < numOwnedMutantAlex; i++) {
          let id = await mutantAlex.tokenOfOwnerByIndex(address, i);
          let uri = await mutantAlex.tokenURI(id);
          const res = await fetch(uri);
          const data = await res.json();
          data.contractAddress = mutantAlexData.polyAddress;
          data.attributes["Token_Bonus"] = 0;
          data.blockchain = "Polygon";
          tokensOwned.push(data);
        }
      }
    }

    const data = tokensOwned[0];
    const attributeKeys = Object.keys(data.attributes);
    const attributeValues = Object.values(data.attributes);

    this.setState({
      tokensOwned: tokensOwned,
      name: data.name,
      description: data.description,
      attributeKeys: attributeKeys,
      attributeValues: attributeValues,
      attributes: data.attributes,
      imageUrl: data.image_url || data.image,
      layers: data.layers,
      tokenId: data.token_id,
      token_bonus: data.attributes["Token_Bonus"],
      selectedIndex: 0,
      contractAddress: data.contractAddress,
      blockchain: data.blockchain,
    });
  };

  rotateLeft = () => {
    let index;
    if (this.state.selectedIndex === 0) {
      index = this.state.tokensOwned.length - 1;
    } else {
      index = this.state.selectedIndex - 1;
    }

    const data = this.state.tokensOwned[index];
    const attributeKeys = Object.keys(data.attributes);
    const attributeValues = Object.values(data.attributes);

    this.setState({
      name: data.name,
      description: data.description,
      attributeKeys: attributeKeys,
      attributeValues: attributeValues,
      attributes: data.attributes,
      imageUrl: data.image_url || data.image,
      layers: data.layers,
      tokenId: data.token_id,
      token_bonus: data.attributes["Token_Bonus"],
      selectedIndex: index,
      contractAddress: data.contractAddress,
      blockchain: data.blockchain,
    });
  };

  rotateRight = () => {
    let index;
    if (this.state.selectedIndex === this.state.tokensOwned.length - 1) {
      index = 0;
    } else {
      index = this.state.selectedIndex + 1;
    }

    const data = this.state.tokensOwned[index];
    const attributeKeys = Object.keys(data.attributes);
    const attributeValues = Object.values(data.attributes);

    this.setState({
      name: data.name,
      description: data.description,
      attributeKeys: attributeKeys,
      attributeValues: attributeValues,
      attributes: data.attributes,
      imageUrl: data.image_url || data.image,
      layers: data.layers,
      tokenId: data.token_id,
      token_bonus: data.attributes["Token_Bonus"],
      selectedIndex: index,
      contractAddress: data.contractAddress,
      blockchain: data.blockchain,
    });
  };

  render() {
    let properties;
    if (this.state.tokensOwned.length > 0) {
      properties = (
        <ProfileProperties
          attributes={this.state.attributes}
          attributeKeys={this.state.attributeKeys}
          attributeValues={this.state.attributeValues}
          className="tile description"
        ></ProfileProperties>
      );
    } else {
      properties = null;
    }

    return (
      <div className="profile">
        <div className="nft-container">
          <div className="left-column">
            <img className="carousel-leftImage image" src={this.state.imageUrl} alt="nft"></img>

            {this.state.name && (
              <div className="arrow-box">
                <img className="leftArrow arrows" src={leftArrow} onClick={this.rotateLeft} alt="arrow-left" />
                <img className="rightArrow arrows" src={rightArrow} onClick={this.rotateRight} alt="arrow-right" />
              </div>
            )}
            {this.state.tokensOwned.length > 0 && this.state.blockchain === "Ethereum" && (
              <LaunchGame signer={this.props.signer} layers={this.state.layers}></LaunchGame>
            )}
          </div>
          <div className="right-column">
            <ProfileDescription
              name={this.state.name}
              description={this.state.description}
              className="tile description"
            ></ProfileDescription>

            <ProfileDetails
              token_id={this.state.tokenId}
              token_bonus={this.state.token_bonus}
              contractAddress={this.state.contractAddress}
              blockchain={this.state.blockchain}
              className="tile description"
            ></ProfileDetails>
            {properties}
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;

import React from 'react';
import reactDom from 'react-dom';
import './profile-description.css';

class ProfileDescription extends React.Component {
  render() {
    return (
      <React.Fragment>
          <div className='tile description'>
            <h1>Description</h1>
            <p>{this.props.name}</p>
            <br></br>
            <p>{this.props.description}</p>
          </div>
      </React.Fragment>
    );
  }
}

export default ProfileDescription;

import React from "react"
import Card from "./card"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"

import "swiper/swiper.min.css"
import "./gallery.css"
import imageOne from "../../assets/img/gallery-image-1.png"
import imageTwo from "../../assets/img/gallery-image-3.png"
import imageThree from "../../assets/img/gallery-image-5.png"
import imageFour from "../../assets/img/gallery-image-7.png"
import imageFive from "../../assets/img/gallery-image-4.png"
import imageSix from "../../assets/img/gallery-image-6.png"
import imageSeven from "../../assets/img/gallery-image-2.png"
import imageEight from "../../assets/img/gallery-image-8.png"

import MutateImageOne from "../../assets/img/alex/mutate-alex-1.png"
import MutateImageTwo from "../../assets/img/alex/mutate-alex-3.png"
import MutateImageThree from "../../assets/img/alex/mutate-alex-5.png"
import MutateImageFour from "../../assets/img/alex/mutate-alex-7.png"
import MutateImageFive from "../../assets/img/alex/mutate-alex-4.png"
import MutateImageSix from "../../assets/img/alex/mutate-alex-6.png"
import MutateImageSeven from "../../assets/img/alex/mutate-alex-2.png"

const Gallery = ({ type }) => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ]

  const items = [
    { img: imageOne, alt: "one" },
    { img: imageTwo, alt: "two" },
    { img: imageThree, alt: "three" },
    { img: imageFour, alt: "four" },
    { img: imageFive, alt: "five" },
    { img: imageSix, alt: "six" },
    { img: imageSeven, alt: "seven" },
    { img: imageEight, alt: "eight" },
  ]
  const mutateItems = [
    { img: MutateImageOne, alt: "one" },
    { img: MutateImageTwo, alt: "two" },
    { img: MutateImageThree, alt: "three" },
    { img: MutateImageFour, alt: "four" },
    { img: MutateImageFive, alt: "five" },
    { img: MutateImageSix, alt: "six" },
    { img: MutateImageSeven, alt: "seven" },
  ]

  switch (type) {
    case "alex":
      return (
        <div id="gallery">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            centeredSlides={false}
            modules={[Autoplay]}
            loop={true}
            className="mySwiper"
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
          >
            {/* <Carousel className="carouselone" breakPoints={breakPoints}>
            {items.map((item) => (
              <Card img={item.img} alt={item.alt} key={item.alt} />
              ))}
          </Carousel> */}
            {items.map((item) => (
              <SwiperSlide key={item.alt}>
                <Card img={item.img} alt={item.alt} key={item.alt} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )
    case "mutate-alex":
      return (
        <div id="gallery">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            centeredSlides={false}
            modules={[Autoplay]}
            loop={true}
            className="mySwiper"
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
          >
            {mutateItems.map((item) => (
              <SwiperSlide key={item.alt}>
                <Card img={item.img} alt={item.alt} key={item.alt} />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* <Carousel className="carouselone" breakPoints={breakPoints}>
            {mutateItems.map((item) => (
              <Card img={item.img} alt={item.alt} key={item.alt} />
            ))}
          </Carousel> */}
        </div>
      )

    default:
      return null
  }
}

export default Gallery

import React from "react";
import "./card-one.css";

class CardOne extends React.Component {
  render() {
    return (
      <div id="card">
        <h1>SOMETHING FOR NOTHING</h1>
        <p className="first">
          <a href="https://alkimiexchange.com/" target="blank">
            Alkimi
          </a>{" "}
          is offering Websites and Advertisers the ability to buy and sell digital advertising with radical transparency and significantly
          lower usage fees.
        </p>
        <p className="second">
          To support the millions of transactions in the digital advertising ecosystem $ADS tokens will be required to be staked in the
          Bandwidth Pool. This will be opening at the end of November.
        </p>
        <p className="last">
          This bandwidth pool will be supporting the Something for Nothing Program; which the world’s best publishers will be joining on
          their first step to changing the way digital advertising is traded.
        </p>
      </div>
    );
  }
}

export default CardOne;

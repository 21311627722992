import React from "react"
import "../CardOne/card-one.css"
import table from "../../assets/img/table-3.png"

class CardThree extends React.Component {
  render() {
    return (
      <div id="card">
        <h1>ALKIMIST NFTs</h1>
        <p className="first">
          There are 3000 Alkimist NFTs up for grabs. When you stake 10,000 $ADS* tokens you will have a chance to win an NFT.
        </p>
        {/* <p className="second">
          *If you stake less than 10,000 $ADS tokens you will receive a Community NFT as well as rewards once the staking pool has closed
        </p> */}
        <p className="third">
          For Example: 10,000 $ADS tokens + 1 Ultra Rare Alkimist = 50,000 $ADS + 5000 $ADS APY 10,000 $ADS tokens + 1 Rare Alkimist =
          12,500 $ADS + 1250 $ADS APY
        </p>
        <p className="last">
          There will also be other bonuses; access to the Alkimi Whale Room, Alkimi swag, early access to product releases and more…
        </p>
        <div className="table">
          <img className="welcome-image" src={table} alt="table"></img>
        </div>
      </div>
    )
  }
}

export default CardThree

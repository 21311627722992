import React, { useEffect, useState } from "react"
import "./overlay.css"
import PortalAnimation from "./PortalAnimation"
import explosion from "../../assets/img/mutation/explosion.gif"
import mutationExample from "../../assets/img/mutation/mutation-example.png"

const Overlay = ({ close, tokenURI, activeElement, activeNft }) => {
  const [mutatedAlex, setMutatedAlex] = useState(false)
  const [animationStatus, setAnimationStatus] = useState("loading")

  useEffect(() => {
    mutateAlex()
  }, [])

  const mutateAlex = async () => {
    // get mutated alex { activeElement, activeNft }
    const res = await fetch(tokenURI)
    const data = await res.json()
    revealAlex(data.image, data.name)
  }

  const revealAlex = (image, name) => {
    setAnimationStatus("reveal")
    setMutatedAlex({
      img: image,
      id: name,
    })

    setTimeout(() => {
      setAnimationStatus("done")
    }, 1300)
  }

  return (
    <div id="overlay">
      <button className="close" onClick={close}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </button>
      <View animationStatus={animationStatus} mutatedAlex={mutatedAlex} />
    </div>
  )
}

const View = ({ animationStatus, mutatedAlex }) => {
  switch (animationStatus) {
    case "loading":
      return <PortalAnimation />
    case "reveal":
      return (
        <>
          <PortalAnimation fade />
          <div className="new-alex">
            <img className="explosion" width={500} height={500} src={explosion} alt="explosion" />
          </div>
        </>
      )
    case "done":
      return (
        <div className="new-alex">
          <h2>Experiment Successful !</h2>
          <img className="jump-in" width={500} height={500} src={mutatedAlex.img} alt={mutatedAlex.id} />
        </div>
      )
  }
}

export default Overlay

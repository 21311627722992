import React from "react";
import "./claim.css";
import { ethers } from "ethers";
import * as nftData from "../../assets/data/abi/AlkimiNFTs.json";

class Claim extends React.Component {
  claimNFT = async () => {
    try {
      let nfts = new ethers.Contract(nftData.ethAddress, nftData.abi, this.props.signer);
      console.log(nfts);
      const tx = await nfts.connect(this.props.signer).claimNFT();
      tx.wait();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <div className="Claim-container">
        <button className="App-button-two" onClick={this.claimNFT}>
          Claim your NFT
        </button>
      </div>
    );
  }
}

export default Claim;

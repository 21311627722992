import React from "react";
import "./profile-properties.css";

class PropertyBox extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="property-box">
          <div className="property one">
            <p className="trait">{this.props.propertyName}</p>
            <p className="type">{this.props.propertyValue}</p>
            {/* <p className='rarity'>6% Have this trait</p> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PropertyBox;

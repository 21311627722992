import React from "react";
import "../CardOne/card-one.css";

class CardTwo extends React.Component {
  render() {
    return (
      <div id="card">
        <h1>THE STAKING POOL</h1>
        <p className="first">
          The staking pool will open on 24th November 2021 and shut 1 month later on the 24th December 2021. Users can join the staking pool
          via{" "}
          <a href="https://lattice.exchange/" target="blank">
            Lattice Exchange
          </a>
          , where they can also find out more details about staking on Lattice.
        </p>
        <p className="second">
          The Bandwidth Pool has a limit of 30,000,000 $ADS tokens offering a 10% APY. $ADS tokens staked in the Bandwidth Pool will be
          locked for a minimum of 3 months. There will be no cap on the individual staking limit.
        </p>
        <p className="third">
          The APY is non-linear and will increase over time; those staking for 12 months will achieve the full 10% APY.
        </p>
        <p className="last">
          “The Bandwidth pool marks the inaugural user-facing product on{" "}
          <a href="https://alkimiexchange.com/" target="blank">
            Alkimi Exchange
          </a>{" "}
          and is a crucial element of the ecosystem. By staking their $ADS tokens, users will be rewarded for lending tokens to advertisers
          and publishers. It means for the first time users are effectively being rewarded for their participation in the value exchange of
          the internet.”
        </p>
      </div>
    );
  }
}

export default CardTwo;

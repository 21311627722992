import React from "react";
import "./profile-details.css";

class ProfileDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="tile details">
        <h1>Details</h1>
        <table className="table">
          <tbody>
            <tr>
              <td>Contract Address</td>
              <td>{this.props.contractAddress}</td>
            </tr>
            <tr>
              <td>Token ID</td>
              <td>{this.props.token_id}</td>
            </tr>
            <tr>
              <td>Token Standard</td>
              <td>ERC-721</td>
            </tr>
            <tr>
              <td>Blockchain</td>
              <td>{this.props.blockchain}</td>
            </tr>
            <tr>
              <td>Token Bonus</td>
              <td>{this.props.token_bonus}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ProfileDetails;

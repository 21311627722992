import React from "react"
import "./community.css"
import discord from "../../assets/img/discord.png"
import twitter from "../../assets/img/twitter.png"
import telegram from "../../assets/img/Telegram-logo.png"

class Community extends React.Component {
  render() {
    return (
      <div id="community">
        <div className="one">
          <h1>JOIN THE COMMUNITY</h1>
        </div>
        <div className="two">
          <a href="https://discord.gg/Mjqc76qd" target="_blank" rel="noreferrer">
            <button className="follow">
              <img className="" src={discord} alt="discord"></img>
              <span className="btn-text">Join the Discord</span>
            </button>
          </a>
        </div>
        <div className="three">
          <a href="https://twitter.com/alkimiexchange?lang=en" target="_blank" rel="noreferrer">
            <button className="follow">
              <img className="" src={twitter} alt="twitter"></img>
              <span className="btn-text">Follow us on Twitter</span>
            </button>
          </a>
        </div>
        <div className="four">
          <a href="https://t.me/Alkimi_Exchange" target="_blank" rel="noreferrer">
            <button className="follow">
              <img className="" src={telegram} alt="telegram"></img>
              <span className="btn-text">Join our Telegram</span>
            </button>
          </a>
        </div>
      </div>
    )
  }
}

export default Community

import React, { useState } from "react"
import "./wallet.css"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import stargazer from "../../assets/img/stargazer.png"
import metamask from "../../assets/img/metamask.png"
import { ethers } from "ethers"
// import * as nftData from "../../assets/data/abi/AlkimiNFTs-Etherium.json";
import * as nftData from "../../assets/data/abi/AlkimiNFTs.json"
import * as mutantAlexData from "../../assets/data/abi/AlkimiMutantAlex.json"
import * as polygonAlexData from "../../assets/data/abi/PolygonCommunityAlex.json"

const Wallet = ({ onWalletConnected, setWarning }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const loadEthers = async () => {
    if (!window.ethereum) {
      setWarning({
        type: "error",
        msg: "Please install MetaMask to use this application",
      })
      return
    }

    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then(handleAccountsChanged())
      .catch((error) => {
        if (error.code === 4001) {
          // EIP-1193 userRejectedRequest error
          console.log("Please connect to MetaMask.")
        } else {
          console.error(error)
        }
      })
  }
  const connectStargazer = async () => {
    // await window.stargazer.enable();
    if (!window.stargazer) {
      setWarning({ type: "error", msg: "Please install Stargazer to use this applicationx" })
      return
    }
    // let accounts = await window.stargazer.request({ method: "eth_requestAccounts" })
    // const provider = ethers.getDefaultProvider("homestead")
    // const starProvider = await window.stargazer.getProvider("ethereum")
    // const web3provider = new ethers.providers.Web3Provider(starProvider)
    // const signer = web3provider.getSigner()

    const provider = window.stargazer.getProvider("ethereum")

    const activated = await provider.activate("A Cool App Name")

    if (!activated) return

    const ethersProvider = new ethers.providers.Web3Provider(provider)

    const signer = ethersProvider.getSigner()
    const walletAddress = await signer.getAddress()

    const nfts = new ethers.Contract(nftData.ethAddress, nftData.abi, ethersProvider)
    const nftBalance = await nfts.balanceOf(walletAddress)
    onWalletConnected((prev) => ({ ...prev, isConnected: true, signer: signer, nftBalance: nftBalance, walletType: "stargazer" }))
  }

  const handleAccountsChanged = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const address = await signer.getAddress()
      const chainId = await signer.getChainId()
      let nftBalance
      if (chainId === 1337) {
        // Localnet
        let nfts = new ethers.Contract(nftData.localNetAddress, nftData.abi, signer)
        let polyAlex = new ethers.Contract(polygonAlexData.localNetAddress, polygonAlexData.abi, signer)
        let mutantAlex = new ethers.Contract(mutantAlexData.localNetAddress, mutantAlexData.abi, signer)
        nftBalance = await nfts.balanceOf(address)
        nftBalance = nftBalance.add(await polyAlex.balanceOf(address))
        nftBalance = nftBalance.add(await mutantAlex.balanceOf(address))
      } else if (chainId === 137) {
        // Polygon
        console.log("Hello")
        console.log(address)
        let polyAlex = new ethers.Contract(polygonAlexData.polyAddress, polygonAlexData.abi, signer)
        let mutantAlex = new ethers.Contract(mutantAlexData.polyAddress, mutantAlexData.abi, signer)
        nftBalance = await polyAlex.balanceOf(address)
        nftBalance = nftBalance.add(await mutantAlex.balanceOf(address))
      } else if (chainId === 1) {
        // Ethereum Mainnet
        let nfts = new ethers.Contract(nftData.ethAddress, nftData.abi, signer)
        nftBalance = await nfts.balanceOf(address)
      }
      onWalletConnected((prev) => ({ ...prev, isConnected: true, signer: signer, nftBalance: nftBalance, walletType: "metamask" }))
    } catch (e) {
      console.log(e)
      setWarning({ msg: "Please sign in to your wallet" })
    }

    // how many NFTs does this address own
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="wallet-button">Connect to Wallet</DropdownToggle>
      <DropdownMenu className="drop-down-menu">
        <DropdownItem onClick={loadEthers}>
          <div className="name">MetaMask</div>
          <div className="name">
            <img src={metamask} alt="metamask logo"></img>
          </div>
        </DropdownItem>
        <DropdownItem onClick={connectStargazer}>
          <div className="name">Stargazer</div>
          <div className="name">
            <img src={stargazer} alt="stargazer logo"></img>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default Wallet
